import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  withStyles,
  Link,
  Grid,
} from "@material-ui/core";

import httpService from "@services/httpService";
import TextField from "@ui/Fields/TextField";

import ConfirmEmailDialog from "./ConfirmEmailDialog/Index";
import DeviseScreen from "./DeviseScreen";
import styles from "./styles";

import { themeProviderWrapper } from "@contexts/ThemeProvider";
import { errorHandlerProviderWrapper } from "@contexts/ErrorHandlerProvider";

const LoginPage = ({
  classes,
  logo_url,
  can_sign_up,
  background_image_url,
  messages,
  handleError,
}) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    remember_me: false,
  });

  const [dialogState, setDialogState] = useState({ isOpen: false, name: "" });

  useEffect(() => {
    (messages || []).forEach((item) => {
      handleError(item[1], { variant: item[0] }); // item[0] == variant, item[1] == message
    });
  }, []);

  const handleCloseDialog = () => setDialogState({ isOpen: false, name: "" });

  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await httpService.post("", { user: state });

      location.replace(response.data.url);
    } catch ({ response }) {
      if (response && response.data) {
        const data = response.data;

        if (
          typeof data.meta === "object" &&
          data.meta.status === "unconfirmed"
        ) {
          setDialogState({ isOpen: true, name: data.meta.name });
        } else {
          handleError(response.data.message);
        }
      } else {
        handleError(I18n.t("errors.default"));
      }
    }
  };

  return (
    <DeviseScreen
      classes={classes}
      logo_url={logo_url}
      background_image_url={background_image_url}
    >
      <form className={classes.Login__form} onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <TextField
              value={state.email}
              onChange={handleChange}
              label={I18n.t("activerecord.attributes.v4/users.email")}
              name="email"
              type="email"
              classes={classes}
              InputLabelProps={{
                classes: {
                  root: classes.Login__form_field_label,
                  focused: classes.Login__form_field_label_focused,
                },
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.Login__form_field_outlined_input,
                  focused: classes.Login__form_field_focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item>
            <TextField
              value={state.password}
              onChange={handleChange}
              InputLabelProps={{
                classes: {
                  root: classes.Login__form_field_label,
                  focused: classes.Login__form_field_label_focused
                },
                shrink: true
              }}
              inputProps={{ maxLength: 24 }}
              InputProps={{
                classes: {
                  root: classes.Login__form_field_outlined_input,
                  focused: classes.Login__form_field_focused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              label={I18n.t("activerecord.attributes.v4/users.password")}
              name="password"
              type="password"
              variant="outlined"
              classes={classes}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <div className={classes.Login_actions}>
          <FormControlLabel
            classes={{
              label: classes.Login__form_field_label,
            }}
            control={
              <Checkbox
                checked={state.remember_me}
                classes={{
                  root: classes.Login__form_field_checkbox,
                  checked: classes.Login__form_field_checkbox,
                }}
                onChange={(event) =>
                  handleChange({
                    target: {
                      name: "remember_me",
                      value: Number(event.target.checked),
                    },
                  })
                }
              />
            }
            label={I18n.t("activerecord.attributes.v4/users.remember_me")}
          />

          <Link
            href={`/users/password/new`}
            target="_blank"
            className={`${classes.Login__password_recovery}`}
            variant="body2"
          >
            {I18n.t("devise.passwords.new.forgot_password")}
          </Link>
        </div>

        <div className={classes.Login__form_actions}>
          <Button
            className={classes.Login__form_action}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            {I18n.t("login")}
          </Button>
        </div>
      </form>

      {can_sign_up && (
        <>
          <Typography>{I18n.t("devise.sessions.new.can_sign_up")}</Typography>
          <Button
            className={classes.Login__signUp_button}
            variant="outlined"
            fullWidth
            size="large"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `/sign_up?l=${I18n.locale}`;
            }}
          >
            {I18n.t("sign_up")}
          </Button>
        </>
      )}

      <ConfirmEmailDialog
        name={dialogState.name}
        open={dialogState.isOpen}
        onClose={handleCloseDialog}
        email={state.email}
        handleError={handleError}
      />
    </DeviseScreen>
  );
};

export default errorHandlerProviderWrapper(
  themeProviderWrapper(withStyles(styles)(LoginPage))
);
